<script>
export default {
  name: "CurrentBasket",

  props: ["lastAddedItemId"],
  emits: ["itemAdd", "openBasket", "openCurrentBasket", "openLineNote"],

  methods: {
    openNote() {
      if (!this.currentItem) return

      this.$emit("openLineNote", this.currentItem)
    },

    openBasket(e) {
      const ignoreElements = [this.$refs.openNoteRef, this.$refs.addButtonRef].filter(Boolean)
      if (ignoreElements.some((el) => el.contains(e.target))) {
        return
      }

      this.$emit("openBasket")
    },

    openCurrentBasket() {
      this.$emit("openCurrentBasket")
    },
  },

  computed: {
    qty() {
      if (!this.lastAddedItemId) return 0

      return (this.transaction.lines ?? []).reduce((acc, line) => {
        if (String(line.item_id) === String(this.lastAddedItemId)) {
          return acc + (line.quantity || 1)
        }

        return acc
      }, 0)
    },

    lineSubtotal() {
      if (!this.currentItem) return 0

      return this.qty * (this.currentItem.rate ?? 0)
    },

    subTotal() {
      return this.$store.getters["transaction/table"].amount || 0
    },

    currentItem() {
      if (!this.lastAddedItemId || !this.transaction?.lines) return null

      return this.transaction.lines.find(
        (line) => String(line.item_id) === String(this.lastAddedItemId)
      )
    },

    transaction() {
      return this.$store.getters["transaction/transaction"]
    },
  },
}
</script>

<template>
  <div
    tabindex="0"
    class="flex flex-col w-full items-start justify-start rounded-lg bg-gray-800 p-3 gap-4 text-left focus:ring-2 focus:ring-inset focus:ring-white"
  >
    <div class="w-full flex items-center gap-2">
      <div class="w-10 flex-shrink-0">
        <span class="font-bold text-lg leading-none">
          {{ qty }}
        </span>
      </div>

      <div class="flex-1 line-clamp-1 overflow-hidden">
        <p
          ref="openNoteRef"
          class="inline-flex font-bold underline underline-dashed"
          @click.prevent="openNote"
        >
          {{ currentItem?.description ?? "No items in basket." }}
        </p>
      </div>

      <div class="flex-shrink-0">
        <button
          ref="addButtonRef"
          class="flex items-center justify-center text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          :class="{ 'opacity-50 cursor-not-allowed': !currentItem }"
          :disabled="!currentItem"
          @click.prevent="currentItem ? $emit('itemAdd', currentItem) : undefined"
        >
          <span class="sr-only">Add Item</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_35_332)">
              <rect
                width="24"
                height="24"
                rx="12"
                fill="#02A388"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 6C12.6628 6 13.2 6.53726 13.2 7.2V10.8H16.8C17.4628 10.8 18 11.3373 18 12C18 12.6628 17.4628 13.2 16.8 13.2H13.2V16.8C13.2 17.4628 12.6628 18 12 18C11.3373 18 10.8 17.4628 10.8 16.8V13.2H7.2C6.53726 13.2 6 12.6628 6 12C6 11.3373 6.53726 10.8 7.2 10.8H10.8V7.2C10.8 6.53726 11.3373 6 12 6Z"
                fill="#242429"
              />
            </g>
            <defs>
              <clipPath id="clip0_35_332">
                <rect
                  width="24"
                  height="24"
                  rx="12"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>

      <div class="w-14 flex-shrink-0 text-right line-clamp-1 overflow-hidden">
        <span class="text-sm font-bold">
          {{ vueNumberFormat(currentItem?.rate ?? 0) }}
        </span>
      </div>
    </div>

    <div class="w-full grid grid-cols-2 gap-4">
      <div
        class="flex items-center gap-3"
        @click.prevent="openBasket"
      >
        <strong class="font-bold text-base">Huidige balans</strong>
        <span class="text-sm font-bold">{{ vueNumberFormat(subTotal) }}</span>
      </div>

      <div
        class="flex items-center justify-end gap-3"
        @click.prevent="openCurrentBasket"
      >
        <strong class="font-bold text-sm">Sub totaal</strong>
        <span class="text-sm font-bold">{{ vueNumberFormat(lineSubtotal) }}</span>
      </div>
    </div>
  </div>
</template>
