<script>
import { matchSorter } from "match-sorter"
import ItemMobile from "./ItemMobile.vue"

const isEmpty = (obj) => Object.keys(obj).length === 0

export default {
  name: "Items",
  inject: ["itemAdd", "itemClear"],
  props: ["group", "items", "search"],

  components: { ItemMobile },

  computed: {
    filteredItems() {
      if (!this.search) return this.items

      return matchSorter(this.items, this.search, {
        keys: ["attributes.description"],
      })
    },

    hasChildren() {
      return this.group && !isEmpty(this.group?.children)
    },

    orphanItems() {
      if (!this.group) return []

      const childItemsKeys = Object.values(this.group.children).reduce((acc, child) => {
        return [...acc, ...child.items.map((item) => item.id)]
      }, [])

      return this.items.filter((item) => !childItemsKeys.includes(item.id))
    },
  },
}
</script>

<template>
  <template v-if="group && !!group?.children">
    <template
      v-for="(child, index) in group.children"
      :key="index"
    >
      <h3 class="text-base font-bold px-2 py-1 my-2">
        {{ child.name }}
      </h3>

      <div class="grid grid-cols-3 gap-3">
        <ItemMobile
          v-for="(item, index) in child.items"
          :key="index"
          :initialitem="item"
          @itemadd="itemAdd($event)"
          @itemclear="itemClear($event)"
        />
      </div>
    </template>

    <template v-if="orphanItems.length > 0">
      <h3
        v-if="hasChildren"
        class="text-base font-bold px-2 py-1 my-2"
      >
        ---
      </h3>

      <div class="grid grid-cols-3 gap-3">
        <ItemMobile
          v-for="(item, index) in orphanItems"
          :key="index"
          :initialitem="item"
          @itemadd="itemAdd($event)"
          @itemclear="itemClear($event)"
        />
      </div>
    </template>
  </template>

  <template v-else>
    <div class="grid grid-cols-3 gap-3">
      <ItemMobile
        v-for="(item, index) in filteredItems"
        :key="index"
        :initialitem="item"
        @itemadd="itemAdd($event)"
        @itemclear="itemClear($event)"
      />
    </div>
  </template>
</template>
