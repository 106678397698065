<script>
import SelectItemModal from "./SelectItemModal.vue"

export default {
  name: "ItemMobile",
  props: ["initialitem"],
  emits: ["itemadd", "itemclear"],
  components: {
    SelectItemModal,
  },
  methods: {
    addToCart() {
      if (!this.isAvailable) return

      this.$refs.selectItemModal?.addItem?.(false)
    },
  },
  computed: {
    isAvailable() {
      return Boolean(this.initialitem.attributes.available_in_inventory)
    },

    qty() {
      return (this.transaction.lines ?? []).reduce((acc, line) => {
        if (String(line.item_id) === String(this.initialitem.id)) {
          return acc + (line.quantity || 1)
        }

        return acc
      }, 0)
    },

    transaction() {
      return this.$store.getters["transaction/transaction"]
    },
  },
}
</script>

<template>
  <button
    type="button"
    class="relative h-14 flex flex-wrap items-center justify-start overflow-hidden py-2 px-4 bg-gray-800 rounded-md border-r-4 focus:bg-gray-700 focus:outline-none"
    :class="{ 'opacity-50 cursor-not-allowed': !isAvailable }"
    :disabled="!isAvailable"
    :style="`border-color: ${initialitem.attributes?.pos_color ?? 'transparent'}`"
    @click.prevent="addToCart()"
  >
    <span class="line-clamp-2 font-bold leading-tight">
      {{ initialitem.attributes?.pos_description ?? "" }}
    </span>

    <span
      v-if="qty > 0"
      class="text-orange-500 font-bold text-sm absolute top-0.5 left-1"
    >
      {{ qty }}
    </span>
  </button>

  <SelectItemModal
    ref="selectItemModal"
    :initialitem="initialitem"
    @itemadd="$emit('itemadd', $event)"
    @itemclear="$emit('itemclear', $event)"
  />
</template>
