<template>
  <component
    :is="BasketItemsComp"
    :area_id="area_id"
    :table_id="table_id"
  />
</template>

<script>
import TerminalLayoutMixin from "@/mixins/terminal-layout.mixin"
import TableButton from "@/views/mobilepos/pages/TableButton.vue"
import Table from "./Table.vue"

export default {
  name: "mobilepos.TableView",
  props: ["area_id", "table_id"],
  mixins: [TerminalLayoutMixin],
  computed: {
    BasketItemsComp() {
      if (this.isMobileOrderingButtons) {
        return TableButton
      }

      return Table
    },
  },
}
</script>
