import axios from "axios"
import { setupCache } from "axios-cache-adapter"
import md5 from "js-md5"

// todo: make global available
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  invalidate: async (config, request) => {
    if (request.clearCacheEntry) {
      await config.store.removeItem(config.uuid)
    }
  },
})

const axiosCache = axios.create({
  adapter: cache.adapter,
})

export default {
  data() {
    return {
      lastAddedItemId: null,
      selectedGroupId: null,
      searchquery: "",
      groups: [],
      items: [],
      // @deprecated
      cartitems: [],
    }
  },

  methods: {
    itemClear(item_id) {},

    itemAdd(item) {
      // let id = (Math.random()).toString(22).substr(2, 22)
      let self = this
      // set the rate
      let rate = 0
      if (!item.composed_child) {
        rate = item.variant ? item.variant.attributes.price : item.attributes.pos_price
      }

      let id = window.md5(JSON.stringify(item))

      // is the already selected
      let item_exist = false

      // if item alread added, update the quantity
      this.cartitems.forEach((cartitem) => {
        if (cartitem.id == id || cartitem.parent_id == id) {
          item_exist = true
          cartitem.quantity += 1
        }
      })

      if (!item_exist) {
        // set the data of the item
        let data = {
          parent_id: item.line_parent_id ? item.line_parent_id : null,
          item_id: item.id,
          item_parent_id: item.parent_id,
          addon: false,
          composed_child: item.composed_child ? item.composed_child : false,
          quantity: item.quantity,
          barcode: item.attributes.barcode,
          description:
            item.attributes.description +
            (item.variant ? " - " + item.variant.attributes.label : ""),
          thumbnail: item.attributes.thumbnail_url,
          taxrate: item.attributes.taxrate,
          rate_without_discount: rate,
          rate: rate,
          // total:  rate * item.quantity,
          kitchen_groceries: item.kitchen_groceries,

          variant_id: item.variant ? item.variant.id : null,
          variant_label: item.variant ? item.variant.attributes.label : null,

          // addons?!
          addons: item.addons,
          notes: item.notes,
        }
        data.id = md5(JSON.stringify(data))
        data.quantity = item.quantity

        this.cartitems.push(data)

        // add addons
        item.addons.forEach(function (addon) {
          let data_addon = {
            // self.cartitems.push({
            parent_id: data.id,
            item_id: addon.item_id,
            item_parent_id: item.id,
            addon: true,
            quantity: parseInt(addon.quantity),
            barcode: addon.barcode,
            description: addon.description,
            thumbnail: null,
            taxrate: addon.taxrate,
            rate_without_discount: addon.price,
            rate: addon.price,
            // total:  addon.price * parseInt(addon.quantity),
            kitchen_groceries: [],
          }
          data_addon.id = md5(JSON.stringify(data_addon))
          ;(data_addon.quantity = parseInt(addon.quantity)), self.cartitems.push(data_addon)
        })
      }

      this.lastAddedItemId = item.id
      this.save()
    },

    async getItems(clearCache = false) {
      console.log("---> getItems 2: get items")
      this.groups = []
      this.items = []
      // get items (cached)
      await axiosCache({
        url: "/items",
        method: "get",
        clearCacheEntry: clearCache,
      })
        .then((response) => {
          this.groups = response.data.data

          // Select first group
          if (this.groups.length > 0) {
            this.selectedGroupId = this.groups[0].id
          }
        })
        .catch((error) => {
          this.$notify(
            {
              group: "notifications",
              type: "error",
              title: "Fout opgetreden",
              text: "Oeps er ging iets fout..",
            },
            2000
          )
          console.error(error)
        })
    },

    save() {
      let self = this

      console.log("Save transaction...", this.cartitems)

      this.cartitems.forEach((line) => {
        self.$store.dispatch("transaction/addItem", line)
      })

      this.cartitems = []
    },
  },

  computed: {
    allItems() {
      let items = []

      this.groups.forEach((group) => {
        group.items.forEach((item) => {
          items.push(item)
        })
      })

      return items
    },

    indexedItems() {
      return this.allItems.reduce((acc, item) => {
        const groupId = item.attributes.category_id
        if (!acc[groupId]) {
          acc[groupId] = []
        }
        acc[groupId].push(item)
        return acc
      }, {})
    },
  },
}
