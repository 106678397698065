<template>
    <!-- table != undefined ? table.attributes?.name + (table.attributes?.name_temporary ? '(' + table.attributes?.name_temporary + ')' : '') : ''" -->
    <navigation-mobile-pos @titleclicked="$refs.modalTableRename.open()" :title="table_name">
        <div class="flex justify-end">
            <a @click="tableClose()" class="text-white" aria-label="Close">
                <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </a>
        </div>
    </navigation-mobile-pos>

    <div class="flex flex-col stretcher-content">

        <div id="transaction-scroller" class="flex flex-col overflow-auto flex-grow p-4" v-cloak>
            <loading-indicator v-show="!initialized" class="mt-64" />
            <div class="flex-grow text-left">
                <div class="" v-if="initialized">
                    <div class="">
                        <div v-if="table.relationships?.lines.length > 0"
                             class="transaction-content overflow-auto flex flex-col-reverse font-bold">
                            <div class="transaction-line grid grid-cols-12 py-1 px-1 flex items-start"
                                 :class="{'selected' : line.selected}"
                                 v-for="(line, index) in table.relationships.lines_consolidated.slice().reverse()" :key="index">

                                <div class="col-span-1 flex justify-end">
                                    <div class="text-lg leading-5">{{ parseFloat(line.attributes.quantity) }}</div>
                                </div>
                                <div class="col-span-2"></div>
                                <!-- <div class="col-span-2 flex justify-center"></div> -->
                                <div class="col-span-5 text-left">
                                    <div class="text-base leading-5 truncate"
                                         :class="{'ml-3 italic': line.attributes.addon}">{{
                                            line.attributes.description
                                        }}
                                    </div>
                                    <div class="italic text-xs font-normal" v-if="line.attributes.notes">
                                        {{ line.attributes.notes }}
                                    </div>
                                    <div class="italic text-xs font-normal"
                                         v-for="(kitchen_grocery, grocery_index) in line.attributes.kitchen_groceries"
                                         v-bind:key="grocery_index">
                                        {{ grocery_index }}: {{ kitchen_grocery }}
                                    </div>
                                    <div class="italic text-xs ml-3 font-normal" v-if="line.attributes.variant_label">
                                        {{ line.attributes.variant_label }}
                                    </div>
                                </div>
                                <!-- <div class="col-span-2 flex justify-center"></div> -->

                                <a @click="getByBarcode(line)"
                                    :class="{'opacity-100 pointer-events-auto': !line.attributes.parent_id
                                    && !line.attributes.addon
                                    && !line.attributes.variant_id
                                    && hasChild(line.id) === false
                                    && line.attributes.kitchen_groceries.length == 0}"

                                    class="col-span-1 cursor-pointer text-lg leading-5 text-gray-300 opacity-0 pointer-events-none plus-icon">
                                    <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                                    </svg>
                                </a>
                                <div class="text-base col-span-3 flex items-center justify-end">
                                    <div class="flex items-center">

                                        <div>{{ vueNumberFormat(line.attributes.total) }}</div>
                                        <div class="text-xs text-gray-300" v-if="line.attributes.discount_amount > 0">
                                            (-{{ vueNumberFormat(line.attributes.discount_amount) }})
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="transaction">
                        <div v-if="transaction.lines.length > 0 || terminal.transactionscreen == 'empty'"
                             class="transaction-content overflow-auto flex flex-col-reverse">
                            <div class="transaction-line grid grid-cols-12 py-1 px-1 flex items-start new-item"
                                 :class="{'selected' : line.selected}"
                                 v-for="(line, index) in transaction.lines.slice().reverse()" :key="index">

                                <div class="col-span-1 flex justify-end">
                                    <div class="text-lg leading-5">{{ line.quantity }}</div>
                                </div>

                                <div class="col-span-2 flex justify-center">
                                    <a v-if="line.editable" @click="subQuantity(line)"
                                       class="leading-5 px-1 text-gray-300">
                                        <svg class="h-5 w-4" fill="currentColor" viewBox="0 0 20 20">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z" />
                                        </svg>
                                    </a>
                                </div>
                                <div class="col-span-5 text-left">
                                    <div @click="$refs.modalNotes.openByLine(line)"
                                         class="font-bold leading-5 truncate"
                                         :class="{'ml-3 italic': (line.addon || !line.editable)}">{{ line.description }}
                                    </div>


                                    <div class="italic text-xs" v-if="line.notes">
                                        {{ line.notes }}
                                    </div>
                                    <div class="italic text-xs"
                                         v-for="(kitchen_grocery, grocery_index) in line.kitchen_groceries"
                                         v-bind:key="grocery_index">
                                        {{ kitchen_grocery.description }}: {{ kitchen_grocery.value }}
                                    </div>
                                    <div class="italic text-xs ml-3" v-if="line.variant_label">
                                        {{ line.variant_label }}
                                    </div>
                                </div>
                                <div class="col-span-1 flex justify-start">
                                    <a v-if="line.editable" @click="addQuantity(line)"
                                       class="cursor-pointer text-lg leading-5 px-1 text-gray-300">
                                        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                                        </svg>
                                    </a>
                                </div>

                                <div v-if="line.editable"
                                     class="col-span-3 flex items-center justify-end font-bold">
                                    <div>
                                        <div>{{ vueNumberFormat(line.total) }}</div>
                                        <div class="text-xs text-gray-300" v-if="line.discount_amount > 0">
                                            (-{{ vueNumberFormat(line.discount_amount) }})
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="grid grid-cols-12 gap-2 p-2" v-for="(line, index) in lines" :key="index">
                        <div class="col-span-4">
                            <div class="grid grid-cols-12 flex items-center">
                                <div class="col-span-4 flex justify-center items-center">
                                    <a @click="subQuantity(line)" class="border leading-5 p-1 border-gray-500 text-gray-500">
                                        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z" />
                                        </svg>
                                    </a>
                                </div>

                                <div class="col-span-4 flex justify-center">
                                    {{ line.quantity }}
                                </div>

                                <div class="col-span-4 flex justify-center">
                                    <a @click="addQuantity(line)" class="border leading-5 p-1 border-gray-500 text-gray-500">
                                        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6">
                            {{ line.description}}
                            <div class="text-lg font-bold leading-5 truncate" :class="{'ml-3 italic': (line.addon || !line.editable)}">{{ line.description }}</div>
                            <div class="italic text-xs" v-if="line.notes">
                                {{ line.notes }}
                            </div>
                            <div class="italic text-xs" v-for="(kitchen_grocery, grocery_index) in line.kitchen_groceries" v-bind:key="grocery_index">
                                {{ kitchen_grocery.description }}: {{ kitchen_grocery.value }}
                            </div>
                            <div class="italic text-xs ml-3" v-if="line.variant_label">
                                {{ line.variant_label }}
                            </div>
                        </div>
                        <div class="col-span-2 text-right">
                            {{ vueNumberFormat(line.total) }}
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        <footer class="text-white border-t table-footer" v-if="initialized">
            <div class=" font-bold p-4 pb-0">
                <div class="bg-shadow flex justify-between text-lg p-2">
                    <div>{{$t('translations.views.mobilepos.pages.table.total')}}</div>
                    <div>{{ table.attributes.total }}</div>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-3 p-4 pb-0">
                <div class="col-span-1">
                    <modal-item-select @saved="saveLines($event)" @reload="tableGet()" />
                </div>

                <div class="col-span-1 grid grid-cols-2 gap-3">
                    <modal-table-show @reload="tableGet()" />
                    <div @click="printReceiptTable()"
                         class="button-primary-outline rounded shado flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                        </svg>
                    </div>
                </div>
            </div>

            <div class="flex items-center grid grid-cols-2 gap-3 p-4 pt-3" v-if="transaction.items > 0">
                <button @click="tableClose()" :disabled="closing"
                        class="button button-filled button-primary inline-flex items-center justify-center w-full">
                    <span class="text-white" v-if="closing">
                        <svg wire:loading class="animate-spin mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                             fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </span>
                    {{$t('translations.views.mobilepos.pages.table.save')}}
                </button>
                <router-link :to="{name: 'transaction' }"

                             class="button-primary-outline rounded inline-flex justify-center w-full rounded-sm px-4 py-2">
                             {{$t('translations.views.mobilepos.pages.table.pay')}}
                </router-link>
            </div>
            <div class="flex items-center grid grid-cols-2 gap-3 p-4 pt-3"
                 v-else-if="transaction.items == 0 && table.relationships.lines.length > 0">
                <router-link :to="{name: 'transaction' }"

                             class="button-primary rounded inline-flex justify-center w-full rounded-sm  border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                             {{$t('translations.views.mobilepos.pages.table.pay')}}
                </router-link>
            </div>
        </footer>
    </div>

    <!-- modals -->
    <modal-notes ref="modalNotes" />
    <modal-table-rename ref="modalTableRename" @saved="tableGet()" />

</template>

<script>
import axios from 'axios';

// mixins
import PosHelper from '../../../mixins/pos.helper.js';
import ItemHelper from '../../../mixins/item.helper.js';

// components
import NavigationMobilePos from '../../../components/NavigationMobilePos';
import LoadingIndicator from '../../../components/LoadingIndicator';

// modals
import ModalItemSelect from '../../../components/ModalItemSelect';
import ModalTableShow from '../../../components/ModalTableShow';
import ModalTableRename from '../../../components/ModalTableRename';
import ModalNotes from '../../../components/ModalNotes';

export default {
    name: 'mobilepos.table',
    mixins: [ItemHelper, PosHelper],
    props: ['area_id', 'table_id'],
    components: {
        NavigationMobilePos,
        LoadingIndicator,
        ModalItemSelect,
        ModalTableShow,
        ModalTableRename,
        ModalNotes,
    },
    data () {
        return {
            initialized: false,
            closing: false,
            table: null,
        };
    },
    mounted () {
        this.table = null;
        this.tableOpen();
    },

    methods: {
        saveLines (data) {
            this.lines = data;
        },

        hasChild(id) {
            return this.table.relationships.lines.filter(line => line.attributes.parent_id == id).length > 0;
        },

        async getByBarcode(line) {
            let barcode = line.attributes.barcode;

            await axios.post('/items/search-barcode', {
                barcode: barcode
            }).then(response => {
                if (response.data.data) {
                    this.initialitem = response.data.data
                }
            }).catch(error => {
                if (error.response.status == 404) {
                    this.$notify({ group: "notifications", type: 'error', title: "Niet gevonden", text: "Gescande artikel is niet gevonden" }, 2000);
                } else {
                    this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                    console.error(error)
                }
            })



            console.log('LINELINE', line);
            console.log('initialItem', this.initialitem);

            this.addItem();
        },

        async cancel () {
            this.$store.dispatch('transaction/clear');

            this.tableClose();
        },

        async tableOpen () {
            await axios.get('/tables/' + this.table_id + '/open').then(response => {
                this.table = response.data.data;
                this.$store.dispatch('transaction/setTable', this.table);

            }).catch(() => {
                console.log('error');
                // this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
            }).finally(() => {
                this.initialized = true;
            });

            let transactionContent = document.getElementById('transaction-scroller');
            transactionContent.scrollTop = transactionContent.scrollHeight;
        },

        async tableClose () {
            this.closing = true;
            if (!this.transaction.table_id) {
                return;
            }

            let transaction = this.$store.getters['transaction/transaction'];
            transaction.lines = transaction.lines.filter(line => !line.defaultItem);

            // close table
            await axios.post('/tables/' + this.transaction.table_id + '/close', transaction).then(() => {
                // this.$notify({ group: "notifications", type: 'success', title: 'Tafel ' + this.transaction.table_name, text: "Tafel gesloten" }, 1000);
                this.$store.dispatch('transaction/clear');

            }).catch(() => {
                this.$store.dispatch('transaction/clear');

                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Mislukt",
                    text: "Sluiten van de tafel",
                }, 2000);
            }).finally(() => {
                this.$router.push({ name: 'mobilepos.tables', params: { area_id: this.area_id } });
                this.closing = false;
            });
        },

        async tableGet () {
            axios.get('/tables/' + this.table_id).then(response => {
                this.table = response.data.data;
            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            }).finally(() => {
                this.initialized = true;
            });
        },

        tableSelect (table) {
            this.$store.dispatch('transaction/setTable', table);
            this.$router.push({ name: 'pos' });
        },
    },
    computed: {
        terminal () {
            return this.$store.getters['terminal/terminal'];
        },

        table_name () {
            if (this.table) {
                return this.table.attributes.name + (this.table.attributes?.name_temporary ? ' (' + this.table.attributes?.name_temporary + ')' : '');
            }
            return '';
        },
    },
};
</script>
