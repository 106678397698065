<script>
import Numpad from "@/components/Numpad.vue"
import ItemHelper from "@/mixins/item.helper.js"

export default {
  name: "SelectItemModal",
  props: ["initialitem", "initialquantity"],
  emits: ["itemadd", "itemclear"],
  mixins: [ItemHelper],
  components: {
    Numpad,
  },
  methods: {
    clearItem(item_id) {
      this.$emit("itemclear", item_id)
    },

    addItemToCart(item) {
      this.$emit("itemadd", item)
    },
  },
}
</script>

<template>
  <teleport to="#mobile-modal">
    <div
      v-if="show_modal"
      class="fixed z-30 inset-0 overflow-y-auto"
    >
      <div
        class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div
          id="modal"
          class="w-full add-ons inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="modal-content">
            <div class="sm:p-0 flex items-center justify-between mb-5">
              <div class="sm:mt-0 sm:text-left">
                <h3
                  class="modal-title text-lg leading-6 font-bold text-2xl"
                  id="modal-headline"
                >
                  {{ item.attributes.webshop_description }}
                </h3>
              </div>
              <div class="">
                <a
                  @click="close()"
                  class="focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                  aria-label="Close"
                >
                  <svg
                    class="h-8 w-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </a>
              </div>
            </div>

            <div class="modal-overflow-wrapper">
              <!-- Details -->
              <div v-if="step == 'details'">
                <div class="mt-2">
                  <div
                    v-if="item.attributes.thumbnail_url"
                    class="flex justify-center"
                  >
                    <img
                      class="max-h-20 h-auto"
                      :src="item.attributes.thumbnail_url"
                      alt=""
                    />
                  </div>
                  <p>{{ item.attributes.webshop_description_long }}</p>
                </div>
              </div>

              <!-- OpenPrice -->
              <div v-if="step == 'openprice'">
                <h2 class="my-2 text-lg font-bold">
                  {{ $t("translations.components.item-mobile.give-price") }}
                </h2>
                <div class="h-5">
                  <div
                    class="text-xs text-red-600"
                    v-if="errors.openprice"
                  >
                    {{ $t("translations.components.item-mobile.invalid-price") }}
                  </div>
                </div>
                <numpad
                  :default="item.attributes.pos_price"
                  @inputchanged="setOpenprice($event); errors.openprice = false"
                />
              </div>

              <!-- Variants -->
              <div v-if="step == 'variants'">
                <div class="grid grid-cols-2 gap-2 justify-center mt-4">
                  <!-- <span class="relative z-0 inline-flex shadow-sm rounded-sm "> -->
                  <div
                    class="w-full"
                    v-for="(item_variant, variant_index) in item.attributes.variants"
                    :key="variant_index"
                  >
                    <!-- avaialble -->
                    <a
                      v-if="item_variant.attributes.available_in_inventory"
                      @click="variantSelect(item_variant)"
                      class="cursor-pointer w-full h-full relative flex justify-between items-center px-4 py-2 -ml-px border text-sm leading-5 font-medium focus:z-10 focus:outline-none"
                      :class="
                        item_variant.id == (variant ? variant.id : null)
                          ? 'btn-primary'
                          : 'btn-secondary'
                      "
                    >
                      <div>{{ item_variant.attributes.label }}</div>
                      <div class="font-bold"><currency />{{ item_variant.attributes.price }}</div>
                    </a>

                    <!-- soldout -->
                    <a
                      v-else
                      class="opacity-25 line-through btn-secondary cursor-pointer w-full relative flex justify-between items-center px-4 py-2 -ml-px border text-sm leading-5 font-medium focus:z-10 focus:outline-none"
                    >
                      <div>{{ item_variant.attributes.label }}</div>
                    </a>
                  </div>
                  <!-- </span> -->
                </div>
                <div
                  class="text-xs text-red-600"
                  v-if="errors.variants && !variant"
                >
                  {{ $t("translations.components.item-mobile.required") }}
                </div>
              </div>

              <!-- Addons -->
              <div v-if="step == 'addons'">
                <div
                  class="mb-3 addon-group"
                  v-for="(addon, addon_index) in addons"
                  :key="addon_index"
                >
                  <div class="font-bold flex justify-between mb-3">
                    <div class="text-xl">
                      {{ addon.description }}
                      <span v-if="addon.quantity_min > 0">*</span>
                    </div>

                    <div>
                      <!-- <div>{{ option.description }} ({{ option.price }})</div> -->
                      <div
                        class="text-xs text-red-600"
                        v-if="addon.error"
                      >
                        {{ $t("translations.components.item-mobile.select") }}

                        <span v-if="addon.quantity_min && addon.quantity_max">
                          {{ $t("translations.components.item-mobile.minimal") }}
                          {{ addon.quantity_min }} en
                          {{ $t("translations.components.item-mobile.maximal") }}
                          {{ addon.quantity_max }}
                        </span>

                        <span v-else-if="addon.quantity_min">
                          {{ $t("translations.components.item-mobile.minimal") }}
                          {{ addon.quantity_min }}
                        </span>

                        <span v-else-if="addon.quantity_max">
                          {{ $t("translations.components.item-mobile.maximal") }}
                          {{ addon.quantity_max }}
                        </span>
                      </div>
                    </div>
                    <!-- <span class="text-xs text-red-600" v-if="hasError('addons', addon.description)">verplicht</span> -->
                  </div>

                  <div
                    class="mb-3"
                    v-for="(option, option_index) in addon.options"
                    :key="option_index"
                  >
                    <!-- Quantity -->
                    <div
                      v-if="addon.type == 'buttons'"
                      class="flex justify-start items-center flex-wrap mb-4"
                    >
                      <div class="flex justify-between font-bold items-center w-full">
                        <div>
                          <div>{{ option.description }} ({{ option.price }})</div>
                          <div
                            class="text-xs text-red-600"
                            v-if="option.error"
                          >
                            <span v-if="option.quantity_min && option.quantity_max"
                              >{{ $t("translations.components.item-mobile.minimal") }}
                              {{ option.quantity_min }} en
                              {{ $t("translations.components.item-mobile.maximal") }}
                              {{ option.quantity_max }}</span
                            >
                            <span v-else-if="option.quantity_min"
                              >{{ $t("translations.components.item-mobile.minimal") }}
                              {{ option.quantity_min }}</span
                            >
                            <span v-else-if="option.quantity_max"
                              >{{ $t("translations.components.item-mobile.maximal") }}
                              {{ option.quantity_max }}</span
                            >
                          </div>
                        </div>
                        <div><currency />{{ vueNumberFormat(option.quantity * option.price) }}</div>
                      </div>
                      <div class="flex rounded-sm items-center justify-end mr-5 mt-3">
                        <div class="">
                          <a
                            @click="changeAddonQuantity(option, -1)"
                            class="block rounded-sm border border-gray-200 p-1 order-action minus"
                          >
                            <svg
                              class="h-5 w-5"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z"
                              />
                            </svg>
                          </a>
                        </div>
                        <div class="px-3">
                          {{ parseInt(option.quantity) }}
                        </div>
                        <div>
                          <a
                            @click="changeAddonQuantity(option, 1)"
                            class="block rounded-sm border border-gray-200 p-1 order-action plus"
                          >
                            <svg
                              class="h-5 w-5"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>

                    <!-- Radio -->
                    <div
                      v-if="addon.type == 'radio'"
                      class="flex justify-between items-center"
                    >
                      <div class="flex items-center justify-start gap-5">
                        <input
                          @change="addonRadioSelect(addon, option)"
                          type="radio"
                          :id="option.id"
                          :name="addon.id"
                          :value="option.id"
                        />
                        <label :for="option.id">
                          {{ option.description }} ({{ option.price }})
                        </label>
                      </div>
                      <div v-if="option.quantity > 0">
                        <currency />
                        {{ vueNumberFormat(option.quantity * option.price) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Kitchen Groceries -->
              <div
                id="kitchengroceries"
                v-if="step == 'kitchen_groceries'"
              >
                <!-- kitchen groceries -->
                <div
                  class="mb-3"
                  v-for="(kitchen_grocery, kitchen_grocery_index) in item.attributes
                    .kitchen_groceries"
                  :key="kitchen_grocery_index"
                >
                  <fieldset>
                    <legend class="w-full font-bold flex justify-between">
                      <span class="capitalize">{{ kitchen_grocery.description }} *</span>
                      <span
                        class="text-xs text-red-600"
                        v-if="hasError('kitchen_groceries', kitchen_grocery.description)"
                      >
                        {{ $t("translations.components.item-mobile.required") }}
                      </span>
                    </legend>
                    <ul
                      class="space-y-1"
                      role="radiogroup"
                      aria-labelledby="radiogroup-label"
                    >
                      <li
                        v-for="(option, option_index) in kitchen_grocery.options.split(';')"
                        :key="option_index"
                        tabindex="0"
                        role="radio"
                        class="group relative rounded-sm shadow-sm cursor-pointer focus:outline-none"
                      >
                        <div
                          @click="setKitchenGrocery(kitchen_grocery.description, option)"
                          class="mt-2 rounded-sm border px-3 py-2 sm:flex sm:justify-between"
                        >
                          <div class="flex items-center">
                            <div class="text-sm">
                              <p class="capitalize font-medium">
                                {{ option }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="isKitchenGrocerySelected(kitchen_grocery.description, option)"
                          class="border-highlight absolute inset-0 rounded-sm border-2 pointer-events-none"
                          aria-hidden="true"
                        />
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer px-4 py-3 sm:px-6 sm:flex sm:justify-between items-center">
            <!-- Quantity -->
            <div class="flex rounded-sm sm:mb-0 mb-4 items-center justify-center sm:justify-end">
              <div class="">
                <a
                  @click="subQuantity()"
                  class="block rounded-sm border border-gray-200 p-1 order-action minus"
                >
                  <svg
                    class="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z"
                    />
                  </svg>
                </a>
              </div>
              <div class="text-main px-5">
                {{ quantity }}
              </div>
              <div>
                <a
                  @click="addQuantity()"
                  class="block rounded-sm border border-gray-200 p-1 order-action plus"
                >
                  <svg
                    class="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div class="sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-sm shadow-sm sm:ml-3 sm:w-auto">
                <a
                  v-if="step == 'details'"
                  @click="nextStep('details')"
                  class="button-primary inline-flex justify-center w-full rounded-sm border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  {{ $t("translations.components.item-mobile.confirm") }}
                </a>
                <a
                  v-if="step == 'openprice'"
                  @click="confirmOpenPrice()"
                  class="button-primary inline-flex justify-center w-full rounded-sm border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  {{ $t("translations.components.item-mobile.confirm") }}
                </a>
                <!--<a
                  v-if="step == 'variants'"
                  @click="confirmVariant()"
                  class="button-primary inline-flex justify-center w-full rounded-sm border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  {{ $t("translations.components.item-mobile.confirm") }}
                </a>-->
                <a
                  v-if="step == 'addons'"
                  @click="confirmAddons()"
                  class="button-primary inline-flex justify-center w-full rounded-sm border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  {{ $t("translations.components.item-mobile.confirm") }}
                </a>
                <a
                  v-if="step == 'kitchen_groceries'"
                  @click="confirmKitchenGroceries()"
                  class="button-primary inline-flex justify-center w-full rounded-sm border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  {{ $t("translations.components.item-mobile.confirm") }}
                </a>
                <!--<a
                  @click="addItemToCart()"
                  class="button-primary inline-flex justify-center w-full rounded-sm border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Toevoegen
                </a>-->
              </span>
              <span class="mt-3 flex w-full rounded-sm shadow-sm sm:mt-0 sm:w-auto">
                <a
                  @click="close()"
                  class="button-primary-outline inline-flex justify-center w-full rounded-sm border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  {{ $t("translations.components.item-mobile.close") }}
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
