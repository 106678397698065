import axios from "axios"

export default {
  data: () => ({
    initialized: false,
    closing: false,
    table: null,
  }),

  mounted() {
    this.table = null
    void this.tableOpen()
  },

  methods: {
    async cancel() {
      this.$store.dispatch("transaction/clear")

      void this.tableClose()
    },

    async tableOpen() {
      await axios
        .get("/tables/" + this.table_id + "/open")
        .then((response) => {
          this.table = response.data.data
          this.$store.dispatch("transaction/setTable", this.table)
        })
        .catch(() => {
          console.log("error")
          // this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
        })
        .finally(() => {
          this.initialized = true
        })

      let transactionContent = document.getElementById("transaction-scroller")
      if (transactionContent) {
        transactionContent.scrollTop = transactionContent.scrollHeight
      }
    },

    async tableClose() {
      this.closing = true
      if (!this.transaction.table_id) {
        return
      }

      let transaction = this.$store.getters["transaction/transaction"]
      transaction.lines = transaction.lines.filter((line) => !line.defaultItem)

      // close table
      await axios
        .post("/tables/" + this.transaction.table_id + "/close", transaction)
        .then(() => {
          // this.$notify({ group: "notifications", type: 'success', title: 'Tafel ' + this.transaction.table_name, text: "Tafel gesloten" }, 1000);
          this.$store.dispatch("transaction/clear")
        })
        .catch(() => {
          this.$store.dispatch("transaction/clear")

          this.$notify(
            {
              group: "notifications",
              type: "error",
              title: "Mislukt",
              text: "Sluiten van de tafel",
            },
            2000
          )
        })
        .finally(() => {
          this.$router.push({
            name: "mobilepos.tables",
            params: { area_id: this.area_id },
          })
          this.closing = false
        })
    },

    async tableGet() {
      axios
        .get("/tables/" + this.table_id)
        .then((response) => {
          this.table = response.data.data
        })
        .catch(() => {
          this.$notify(
            {
              group: "notifications",
              type: "error",
              title: "Fout opgetreden",
              text: "Oeps er ging iets fout..",
            },
            2000
          )
        })
        .finally(() => {
          this.initialized = true
        })
    },
  },

  computed: {
    tableName() {
      if (this.table) {
        const { name, name_temporary } = this.table.attributes

        return name_temporary ? `${name} (${name_temporary})` : name
      }

      return ""
    },

    terminal() {
      return this.$store.getters["terminal/terminal"]
    },

    transaction() {
      return this.$store.getters["transaction/transaction"]
    },
  },
}
