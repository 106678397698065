<template>
    <div class="button-primary-outline rounded shadow flex justify-center" @click="open()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    </div>
    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto mobile-large">
        <div class="flex items-center justify-center h-screen text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <!-- <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203; -->
            <div id="modal"
                 class="modal-mobile-styles mobile-select-colors h-screen sm:h-auto w-screen inline-block align-bottom sm:rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl"
                 role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="sm:h-auto modal-content">

                    <modal-close @close="close()" />

                    <div class="p-3 sm:p-0 text-white flex items-center justify-between mobile-modal-title">
                        <div class="sm:mt-0 sm:text-left">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                {{$t('translations.components.modal-item-select.title')}}
                            </h3>
                        </div>
                    </div>


                    <!-- items -->
                    <div class="px-3 py-2 grid grid-cols-3 gap-2 category-items-wrapper mobile-fill-scroll-wrapper">
                        <div class="col-span-1 overflow-scroll categories scrollbar-hide font-bold text-lg">
                            <div class="truncate mr-4 category-item"
                                 :class="{'bg-gray-200 active rounded-sm': !selected_group.id}"
                                 @click="selectGroup(null)">
                                 {{$t('translations.components.modal-item-select.all-items')}}
                            </div>

                            <!-- {{groups}} -->
                            <div class="truncate mr-4 category-item"
                                 :class="{'bg-gray-200 active rounded-sm': selected_group.id == group.id || selected_group.parent_id == group.id}"
                                 @click="selectGroup(group)"
                                 v-for="(group, index) in groups" :key="index">
                                {{ group.name }}
                            </div>
                        </div>

                        <div class="col-span-2 overflow-scroll items">
                            <input type="search" v-model="searchquery" placeholder="Zoeken"
                                   class="rounded bg-gray-100 py-1 px-2 block w-full search focus:outline-none" />


                            <div class="items-wrapper scrollbar-hide">
                                <div v-if="selected_group && selected_group.children" class="subcategory-wrapper">
                                    <div class="border-b item cursor-pointer py-3 overflow-hidden rounded-sm  "
                                         @click="selectGroup(child)"
                                         v-for="(child, index_child) in selected_group.children" :key="index_child">
                                        {{ child.name }}
                                    </div>

                                    <div class="sjef-border"></div>
                                </div>



                                <!-- v-for="(item, index) in items_filtered" -->
                                <item-mobile
                                    v-for="(item, index) in items_filtered"
                                    :key="index"
                                    :initialitem="item"
                                    :cartitems="cartitems"
                                    @itemadd="itemAdd($event)"
                                    @itemclear="itemClear($event)" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sm:h-auto modal-footer px-4 py-4 sm:px-6 flex gap-3 pt-0">
                    <span class="flex items-center rounded-sm">
                        <a @click="save()"
                           class="button-primary font-bold inline-flex justify-center w-full rounded-sm  border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                           {{$t('translations.components.modal-item-select.save')}}
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import md5 from 'js-md5';
import { setupCache } from 'axios-cache-adapter';

// components
import ItemMobile from './ItemMobile';
import ModalClose from './ModalClose.vue';
// mixins
import PosHelper from '../mixins/pos.helper.js';

// todo: make global available
const cache = setupCache({
    maxAge: 15 * 60 * 1000,
    invalidate: async (config, request) => {
        if (request.clearCacheEntry) {
            await config.store.removeItem(config.uuid);
        }
    },
});
const axiosCache = axios.create({
    adapter: cache.adapter,
});

export default {
    emits: ['reload'],
    mixins: [PosHelper],
    components: {
        ModalClose,
        ItemMobile,
    },
    data () {
        return {
            active: false,
            table: null,
            selected_group: {},
            searchquery: '',
            groups: [],
            items: [],
            cartitems: [],
        };
    },
    methods: {
        itemClear (item_id) {
            this.cartitems = this.cartitems.filter(item => {
                return (item.item_id != item_id && item.item_parent_id != item_id);
            });
        },

        itemAdd (item) {

            // let id = (Math.random()).toString(22).substr(2, 22)
            let self = this;
            // set the rate
            let rate = 0;
            if (!item.composed_child) {
                rate = item.variant ? item.variant.attributes.price : item.attributes.pos_price;
            }

            let id = window.md5(JSON.stringify(item));

            // is the already selected
            let item_exist = false;

            // if item alread added, update the quantity
            this.cartitems.forEach(cartitem => {
                if (cartitem.id == id || cartitem.parent_id == id) {
                    item_exist = true;
                    cartitem.quantity += 1;
                }
            });

            if (!item_exist) {

                // set the data of the item
                let data = {
                    parent_id: item.line_parent_id ? item.line_parent_id : null,
                    item_id: item.id,
                    item_parent_id: item.parent_id,
                    addon: false,
                    composed_child: item.composed_child ? item.composed_child : false,
                    quantity: item.quantity,
                    barcode: item.attributes.barcode,
                    description: item.attributes.description + (item.variant ? ' - ' + item.variant.attributes.label : ''),
                    thumbnail: item.attributes.thumbnail_url,
                    taxrate: item.attributes.taxrate,
                    rate_without_discount: rate,
                    rate: rate,
                    // total:  rate * item.quantity,
                    kitchen_groceries: item.kitchen_groceries,

                    variant_id: item.variant ? item.variant.id : null,
                    variant_label: item.variant ? item.variant.attributes.label : null,

                    // addons?!
                    addons: item.addons,
                    notes: item.notes,
                };
                data.id = md5(JSON.stringify(data));
                data.quantity = item.quantity;

                this.cartitems.push(data);

                // add addons
                item.addons.forEach(function (addon) {
                    let data_addon = {
                        // self.cartitems.push({
                        parent_id: data.id,
                        item_id: addon.item_id,
                        item_parent_id: item.id,
                        addon: true,
                        quantity: parseInt(addon.quantity),
                        barcode: addon.barcode,
                        description: addon.description,
                        thumbnail: null,
                        taxrate: addon.taxrate,
                        rate_without_discount: addon.price,
                        rate: addon.price,
                        // total:  addon.price * parseInt(addon.quantity),
                        kitchen_groceries: [],
                    };
                    data_addon.id = md5(JSON.stringify(data_addon));
                    data_addon.quantity = parseInt(addon.quantity),

                        self.cartitems.push(data_addon);
                });
            }
        },

        async getItems (clearCache = false) {
            console.log('---> getItems 2: get items')
            this.groups = [];
            this.items = [];
            // get items (cached)
            await axiosCache({
                url: '/items',
                method: 'get',
                clearCacheEntry: clearCache,
            }).then(response => {

                this.groups = response.data.data;

            }).catch(error => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
                console.error(error);
            });
        },

        selectGroup (group) {
            if (group !== null) {
                this.selected_group = group;

                return;
            }
            this.selected_group = {};
        },

        async open () {

            console.log('---> getItems 1: open modal item select');
            this.cartitems = [];

            await this.getItems();
            this.active = true;
        },

        itemSearch () {
        },

        close () {
            this.active = false;
        },

        handleItemAdded (items) {
            this.cartitems = items;
        },

        save () {
            let self = this;

            this.cartitems.forEach(line => {
                self.$store.dispatch('transaction/addItem', line);
            });

            this.active = false;
        },


        async tableSave () {

            let lines = [];
            this.table.relationships.lines.forEach(function (line) {
                if (line.attributes.quantity_subtract > 0) {
                    lines.push({
                        id: line.id,
                        quantity_subtract: line.attributes.quantity_subtract,
                    });
                }
            });

            axios.post('/tables/quantities-update', {
                table_id: this.table.id,
                lines: lines,
            }).then(response => {
                this.$store.dispatch('transaction/setTable', response.data.data);
                this.$emit('reload');
                this.close();
            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            });
        },

        subQuantity (line) {
            let quantity = line.attributes.quantity_subtract;
            if (quantity) {
                quantity = quantity + 1;
            } else {
                quantity = 1;
            }

            if (quantity <= line.attributes.quantity) {
                line.attributes.quantity_subtract = quantity;
            }
            //  else {
            // line.attributes.quantity_subtract = line.attributes.quantity - 1
            // }
        },
    },
    computed: {
        items_filtered () {
            let items = [];
            let self = this;

            if (this.selected_group && this.selected_group.id) {
                this.selected_group.items.forEach(function (item) {
                    if (self.searchquery.length > 0) {
                        if (item.attributes.description.toUpperCase().includes(self.searchquery.toUpperCase())) {
                            items.push(item);
                        }
                    } else {
                        items.push(item);
                    }
                });
            } else {
                this.groups.forEach(function (group) {
                    group.items.forEach(function (item) {
                        if (self.searchquery.length > 0) {
                            if (item.attributes.description.toUpperCase().includes(self.searchquery.toUpperCase())) {
                                items.push(item);
                            }
                        } else {
                            items.push(item);
                        }
                    });
                });
            }

            return items;
        },

        transaction () {
            return this.$store.getters['transaction/transaction'];
        },

        isMobile () {
            return this.$store.getters['terminal/isMobile'];
        },
    },
};
</script>
